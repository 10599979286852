









import { Component, Vue } from 'vue-property-decorator';
import { H3ConfigProvider } from '@authine/awesome-ui';
import zhCN from '@authine/antd-vue/lib/locale-provider/zh_CN';

@Component({
  name: 'App',
  components: {
    H3ConfigProvider,
  },
})
export default class App extends Vue {
  locale = zhCN;

  get isMobile () {
    return /Android|webOS|iPhone|iPod|iPad|BlackBerry/i.test(navigator.userAgent);
  }
}
