/*
 * @Date: 2022-06-16 14:52:11
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-09-22 16:55:36
 * @FilePath: /front-public/packages/work-platform/src/router/index.ts
 */
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { isPC, isPhone, removeStorage } from '@WorkPlatform/common/utils';
import mWorkPlatform from './router-list/mWorkPlatform';
import workPlatform from './router-list/workPlatform';
import { getLogoutPageConfig } from '@WorkPlatform/common/logoutConfig';

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push (location) {
  return (originalPush.call(this, location) as any).catch(err => err);
};

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  ...workPlatform,
  ...mWorkPlatform,
  {
    path: '/login',
    name: 'login',
    component: () => import('@WorkPlatform/views/login/index.vue'),
  },
  {
    path: '/set-enterprise',
    name: 'setEnterprise',
    component: () => import('@WorkPlatform/views/login/set-enterprise.vue'),
  },
  {
    path: '*',
    redirect: { name: 'home' },
  },
];
async function getLogoutPageUrl (redirect = true) {
  const redirectUrl: string = window.location.href;
  let address: string = await getLogoutPageConfig();
  if (address) {
    if (!redirect) {
      return address;
    }
    const key = 'redirect_url';
    const reg = /\$\{redirect_url\}/;
    if (reg.test(address)) {
      address = address.replace(reg, `${key}=${encodeURIComponent(redirectUrl)}`);
    } else {
      if (address.includes('?')) {
        address += `&${key}=${encodeURIComponent(redirectUrl)}`;
      } else {
        address += `?${key}=${encodeURIComponent(redirectUrl)}`;
      }
    }
    localStorage.removeItem('logoutPage');
    // window.location.href = address;
  }
  return address;
}

// 初始化路由
const router: VueRouter = new VueRouter({
  mode: 'hash',
  routes,
  base: 'platform',
});

// 守卫导航抛出错误时的处理回调函数
router.onError((error: any) => {
  const pattern = /Loading chunk [a-zA-Z0-9_-]+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);

  if (isChunkLoadFailed) {
    Vue.prototype.$errorcatcher.showMessagePopup({
      title: '错误提示！',
      message: ['Loading chunk xxx failed', '路由懒加载找不到对应的moudle'],
      correctGuide: '系统升级更新，请通过F5刷新页面获取最新资源',
      autoHide: true,
      onClose: () => window.location.reload(),
    });
  }
});

router.beforeEach(async (to: any, from: any, next: any) => {
  const token: any = localStorage.getItem('token');
  if (!token) {
    // token为空：退出登录、token过期、未登录
    document.cookie = 'access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    // const loginName = ['login', 'm-work-platform-login'];
    // if (loginName.includes(to.name)) {
    //   next();
    //   return;
    // }
    const address: string = await getLogoutPageUrl(to.name !== 'login');
    // let address: string = await getLogoutPageConfig();
    removeStorage();
    let redirectUrl: string = window.location.href;
    redirectUrl = redirectUrl.replace('#', 'hashsymbol');
    if (address) {
      // const key = 'redirect_url';
      // const reg = /\$\{redirect_url\}/;
      // if (reg.test(address)) {
      //   address = address.replace(reg, `${key}=${encodeURIComponent(redirectUrl)}`);
      // } else {
      //   if (address.includes('?')) {
      //     address += `&${key}=${encodeURIComponent(redirectUrl)}`;
      //   } else {
      //     address += `?${key}=${encodeURIComponent(redirectUrl)}`;
      //   }
      // }
      // localStorage.removeItem('logoutPage');
      window.location.href = address;
      return;
    }
    if (isPhone() && to.name !== 'm-work-platform-login' && to.name !== 'm-work-platform-change-password') {
      next('/m-work-platform-login');
    } else if (isPC() && to.name !== 'login') {
      next(`/login?redirect_url=${encodeURIComponent(redirectUrl)}`);
    } else {
      next();
    }
    return;
  }
  if (isPhone() && to.name === 'work-platform-home') {
    next('/m-work-platform');
    return;
  } else if (isPC() && to.name === 'm-work-platform-home') {
    next('/work-platform-home');
    return;
  }

  // 如果是登录页面
  // if (to.name === 'm-work-platform-login') {
  //   next('/m-work-platform');
  //   return;
  // } else if (to.name === 'login') {
  //   next('/');
  //   return;
  // }
  if (['m-work-platform-login', 'ligin'].includes(to.name)) {
    const address: string = await getLogoutPageUrl(false);
    if (to.name === 'm-work-platform-login') {
      // if (address) {
      //   window.location.href = address;
      //   return;
      // }
      next('/m-work-platform');
      return;
    } else if (to.name === 'login') {
      if (address) {
        window.location.href = address;
        return;
      }
      next('/');
      return;
    }
  }

  const tenantCode = sessionStorage.getItem('tenantCode');
  if (tenantCode && !to.query.tenantCode) {
    const toQuery = JSON.parse(JSON.stringify(to.query));
    toQuery.tenantCode = tenantCode;
    next({
      path: to.path,
      query: toQuery,
      params: to.params,
    });
  } else {
    next();
  }
});

export default router;
