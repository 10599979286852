import API from '@cloudpivot-shared/domain-api';

export class FlowCenterService {
  /**
   * 获取流程模板
   */
  static getFlowTemplate (params?: any) {
    return API.Global.request({
      url: '/api/todo/todo/get_allworkflow_by_todotype',
      method: 'get',
      params,
    });
  }

  /**
   * 待办列表查询
   */
  static todoSearch (params: any): Promise<any> {
    return API.Global.request({
      url: '/api/todo/todo/search',
      method: 'get',
      params,
    });
  }

  /**
   * 我的发起查询
   */
  static myInitiateWorkflow (params: any): Promise<any> {
    return API.Global.request({
      url: '/api/todo/todo/list_by_initiate',
      method: 'get',
      params,
    });
  }

  /**
   * 保存隐藏字段
   */
  static saveHideField (params?: any): Promise<any> {
    return API.Global.request({
      url: '/api/todo/field',
      method: 'post',
      data: params,
    });
  }

  /**
   * 获取已隐藏字段
   */
  static getHideField (params?: any): Promise<any> {
    return API.Global.request({
      url: '/api/todo/field/get_hide_field',
      method: 'get',
      params,
    });
  }

  /**
   * 获取待办应用列表
   */
  static getAppList (params?: any): Promise<any> {
    return API.Global.request({
      url: '/api/todo/todo/get_allappcode_by_todotype',
      method: 'get',
      params,
    });
  }

  /**
   * 批量提交任务
   */
  static batchSubmit (params?: any): Promise<any> {
    const appEntrance = sessionStorage.getItem('appEntrance') || 'platform';
    return API.Global.request({
      url: `/api/launcher/${params.appCode}/domain_service_runtime/${params.schemaCode}/batch_submit`,
      method: 'post',
      data: params.batch,
      headers: {
        'app-entrance': appEntrance,
      },
    });
  }
}
