import API from '@cloudpivot-shared/domain-api';
const prefix = '/api/commonservice';

export class ModifyPasswordService {
  /**
   * 校验原密码
   */
  static checkOldPassword (params: any, baseUserId: string) {
    return API.Global.request({
      url: `${prefix}/organization/user/${baseUserId}/password/check`,
      method: 'get',
      params,
    });
  }

  /**
   * 修改密码
   */
  static modifyPassword (params: any, baseUserId: string, tenantCode?: string, ruleToken?: string) {
    return API.Global.request({
      url: `${prefix}/organization/user/${baseUserId}/password/modify`,
      method: 'put',
      params,
      headers: {
        ruleToken: ruleToken,
        tenantCode,
      },
    });
  }

  static getPasswordRule () {
    return API.Global.request({
      url: `${prefix}/security/password_rule`,
      method: 'get',
    });
  }

  /**
   * 获取密码规则（正则，提示）
   */
  static getPasswordRuleInfo (ruleToken?: string) {
    return API.Global.request({
      url: '/api/webapi/tenant/sys/security/password_regular_expression',
      method: 'get',
      headers: {
        ruleToken: ruleToken,
      },
    });
  }

  static setPasswordRule (params: any) {
    return API.Global.request({
      url: `${prefix}/security/password_rule`,
      method: 'put',
      data: params,
    });
  }
}
