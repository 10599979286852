/*
 * @Date: 2022-02-16 16:29:30
 * @LastEditors: pengff
 * @LastEditTime: 2022-06-24 12:07:27
 * @FilePath: \front-public\packages\work-platform\src\api\set-enterprise.ts
 */
import API from '@cloudpivot-shared/domain-api';
const commonservicePrefix: string = '/api/commonservice';

export class EnterpriseService {
  static saveEnterprise (data: any) {
    return API.Global.request({
      url: `${commonservicePrefix}/organization/department/init_root`,
      method: 'post',
      data,
    });
  }

  static editEnterprise (data: any) {
    return API.Global.request({
      url: `${commonservicePrefix}/organization/department`,
      method: 'put',
      data,
    });
  }

  static getEnterpriseInfo () {
    return API.Global.request({
      url: `${commonservicePrefix}/organization/department`,
      method: 'get',
    });
  }

  static checkEnterpriseExist () {
    return API.Global.request({
      url: `${commonservicePrefix}/organization/department/check_root`,
      method: 'get',
    });
  }
}
