/**
 * 使用自定义图标, 导入并注册到component，使用：<icon-font type="h-icon-all-search-zj" />,type为图标名称
 */
import { H3Icon } from '@authine/awesome-ui';

interface IconFontCN {
  [propName: string]: any;
}

const IconFont: IconFontCN = {};

/**
 * params [object] Vue vue对象
 * params [string] url 二次开发时开发者自定义图标的 Symbol地址
 */
IconFont.loadingIconFont = (Vue: any, url: string): void => {
  const _IconFont = H3Icon.createFromIconfontCN({
    scriptUrl: url,
  });
  Vue.component('IconFont', _IconFont);
};

export default IconFont;
