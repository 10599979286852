import { LoginService } from '@WorkPlatform/api';

const Global = {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {
    async getAllPerms () {
      const buildPermList = (resources: any[] = []) => {
        const data:any[] = [];
        for (const item of resources) {
          data.push(item);
          if (item.children?.length) {
            data.push(...buildPermList(item.children));
          }
        }
        return data;
      };
      const token: any = localStorage.getItem('token');
      if (!token) return;
      const res: any = await LoginService.getAllPerms();

      if (res.success) {
        const data: any = res.data || {};
        const perm: any = {
          isAllAppPerm: data.allAppPerm,
          permList: buildPermList(data.resources),
          appPermList: data.appPerm,
        };

        localStorage.setItem('perm', JSON.stringify(perm));
      }
    },
  },
  getters: {},
};

export default Global;
