import Vue from 'vue';
import App from './App.vue';
import store from '@WorkPlatform/store';
import router from './router';
import '@authine/awesome-ui/dist/index.css';
import '@authine/awesome-ui/dist/antd-style.css';

import { H3Message, H3Modal as Modal, H3Drawer } from '@authine/awesome-ui';
import './assets/icons/iconfont.css';
import './assets/main.css';
import './api/interceptors';
import { H3Modal, H3Toast } from '@authine/thinking-ui';

Vue.use(Modal);
Vue.use(H3Drawer);
Vue.use(H3Modal);
Vue.use(H3Toast);

export function formatter (data: any) {
  let obj = {
    appCode: '',
    groupCode: '',
    pageGroupCode: '',
    pageCode: '',
    pageType: '',
  };
  const isMobile = /Android|webOS|iPhone|iPod|BlackBerry/i.test(
    navigator.userAgent,
  );
  if (data.pageJson && typeof data.pageJson === 'string') {
    const pageJson = JSON.parse(data.pageJson);
    if (!Array.isArray(pageJson)) return;
    if (isMobile) {
      const mobilePage = pageJson.find(p => p.pageType === 'MOBILE');
      if (mobilePage && mobilePage.pageCode) {
        obj = { ...obj, ...mobilePage };
      } else {
        Vue.prototype.$message.error('当前未绑定移动端页面，请检查设置！');
      }
    } else {
      const allPage = pageJson.find(p => p.pageType === 'ALL');
      const pcPage = pageJson.find(p => p.pageType === 'PC');
      if (allPage && allPage.pageCode) {
        obj = { ...obj, ...allPage };
      } else if (pcPage && pcPage.pageCode) {
        obj = { ...obj, ...pcPage };
      } else {
        Vue.prototype.$message.error('当前未绑定PC端页面，请检查设置！');
      }
    }
  }
  return obj;
}

async function mount () {
  const token: any = localStorage.getItem('token');
  if (token) {
    try {
      await store.dispatch('Global/getAllPerms');
    } catch (error) {
      clearToken();
    }
  }
  Vue.prototype.$message = H3Message;
  Vue.prototype.$confirm = Modal.confirm;
  Vue.prototype.$warning = Modal.warning;
  Vue.prototype.$error = Modal.error;
  Vue.prototype.$info = Modal.info;
  Vue.prototype.$success = Modal.success;
  Vue.prototype.$h3Modal = Vue.prototype.$modal;
  Vue.prototype.$modal = Modal;
  Vue.config.productionTip = false;

  new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app');
}

/**
 * 清除token
 */
function clearToken () {
  // 移除local storage内token相关
  localStorage.removeItem('expireInfo');
  localStorage.removeItem('token');
  // 移除cookie内token
  document.cookie = 'path=/;';
}

export async function startup () {
  mount();
}
