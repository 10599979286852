import { LoginService } from '@WorkPlatform/api/login-service';
import { LoginFormFields } from '@WorkPlatform/common/const';

/**
 * 获取登出页的配置信息
 */
export async function getLogoutPageConfig () {
  const res = await LoginService.getLogoutPageConfig();
  if (res.success) {
    const data = res.data;
    let status = false;
    let address = '';
    for (const item of data) {
      switch (item.propertyCode) {
        case LoginFormFields.CustomLoginAddress:
          address = item.propertyValue;
          break;
        case LoginFormFields.CustomLoginStatus:
          status = !!parseInt(item.propertyValue);
          break;
        default:
          break;
      }
    }
    if (status && address) {
      return address;
    }
  }
  return '';
}
