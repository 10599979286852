/*
 * @Date: 2022-01-17 14:20:47
 * @LastEditors: chenyufan
 * @LastEditTime: 2023-08-08 17:20:09
 * @FilePath: /front-public/packages/work-platform/src/api/work-platform.ts
 */
import API from '@cloudpivot-shared/domain-api';

export class WorkPlatformService {
  /**
   *  添加常用应用配置
   */
  static addUsedApp (data: any) {
    return API.Global.request({
      url: '/api/todo/app/used/config',
      method: 'post',
      data,
    });
  }

  /**
   *  常用应用列表
   */
  static usedAppList () {
    return API.Global.request({
      url: '/api/todo/app/used/config/list',
      method: 'get',
    });
  }

  /**
   *
   * 获取待办列表
   */
  static getTodoList (params: any) {
    return API.Global.request({
      url: '/api/todo/todo/search',
      method: 'get',
      params,
    });
  }

  /**
   * 获取应用列表
   */
  static getAppList (params: any) {
    return API.Global.request({
      url: '/api/todo/app/deploy/search',
      method: 'get',
      params,
    });
  }

  /**
   * 获取流程|应用链接
   */
  static getAppUrl (params: any) {
    return API.Global.request({
      url: '/api/todo/app/deploy/get_app_url',
      method: 'get',
      params,
    });
  }

  /**
   * 获取组织列表
   */
  static getOrgList () {
    return API.Global.request({
      url: '/api/org/user/list_user_multiorg',
      method: 'get',
    });
  }

  /**
   * 设置组织
   */
  static setOrg (data: any) {
    return API.Global.request({
      url: `/api/org/user/choose_multiorg?corpId=${data}`,
      method: 'post',
    });
  }

  /**
   * 获取待办数量
   */
  static getWorkItemCount () {
    return API.Global.request({
      url: '/api/commonservice/todo/count_workitem',
      method: 'get',
    });
  }

  /**
   *根据id查询用户
  */
  static getUserDetail (id:string): Promise<any> {
    return API.Global.request({
      url: `/api/commonservice/organization/user/${id}`,
      method: 'get',
    });
  }

  /**
 * 根据语言或配置分类获取流程操作文本
 * @param data 数据 {language: '',//语言，目前不用 configCategories: []//不传返回所有，流程按钮：flowButton；流程操作：flowAction}
 */
  static getFlowActionText (data: { language?: string; configCategories?: string[] } = {}): Promise<any> {
    return API.Global.request({
      url: '/api/webapi/system/internationalization/get_config',
      method: 'post',
      data,
    });
  }

  /**
   * 获取平台logo和页签logo配置信息
   */
  static getLogoConfig () {
    return API.Global.request({
      url: '/api/webapi/system/config_management/list_by_type?configType=PLAT_LOGO',
      method: 'get',
    });
  }
}
