/**
 * 页面类型
 */
export enum EPageType {
  /**
   * 系统
   */
  SYS = 'SYS',
  /**
   * 第三方页面
   */
  THIRD = 'THIRD',
}

/**
 * 客户端类型
 */
export enum EClientType {
  /**
   * PC
   */
  PC = 'PC',
  /**
   * 移动端
   */
  MOBILE = 'MOBILE',
  /**
   * 打印
   */
  PRINT = 'PRINT',
}

export const pageTypeOptions = [
  {
    label: '系统页面',
    value: EPageType.SYS,
  },
  {
    label: '第三方页面',
    value: EPageType.THIRD,
  },
];

/**
 * 流程状态类型
 */
export enum EFlowStatusType {
  /**
   * 全部 后端无此类型。调用接口 传null 或不传status 字段
   */
  All = 'ALL',
  /**
   * 进行中
   */
  PROCESSING = 'PROCESSING',
  /**
   * 已完成
   */
  COMPLETED = 'COMPLETED',
  /**
   * 已取消
   */
  CANCELED = 'CANCELED',
  /**
   * 异常
   */
  EXCEPTION= 'EXCEPTION'
}

/**
 * 流程状态 列表，对于code与文本
 * ALL- 后端无全部状态，当调用接口 获取全部时需要传 null,或不传类型字段
 */
export const FlowStatusArray = [
  {
    code: EFlowStatusType.All,
    text: '全部',
  },
  {
    code: EFlowStatusType.PROCESSING,
    text: '进行中',
  },
  {
    code: EFlowStatusType.COMPLETED,
    text: '已完成',
  },
  {
    code: EFlowStatusType.CANCELED,
    text: '已作废',
  },
  {
    code: EFlowStatusType.EXCEPTION,
    text: '异常流程',
  },
];

/**
 * 登入登出字段
 * work-platform工作台/management管理平台/front-app-runtime运行时/front-app-studio开发平台需要同时维护
 */
export enum LoginFormFields {
  /** 自定义登出状态 */
  Status = 'status',
  /** 自定义登出地址 */
  Address = 'address',
  /** 自定义登录状态 */
  CustomLoginStatus = 'customLoginStatus',
  /** 自定义登录地址 */
  CustomLoginAddress = 'customLoginAddress',
}

export enum FlowActions {
  // 提交
  Submit = 'submit',
  // 暂存
  Save = 'save',
  // 同意
  Agree = 'agree',
  // 催办
  Urge = 'urge',
  // 批量处理
  BatchOperate = 'batchOperate',
  // 不同意
  DisAgree = 'disAgree',
  // 转办
  Forward = 'forward',
  // 撤回
  Retrieve = 'retrieve',
  // 加签
  AdjustParticipant = 'adjustParticipant',
  // 协办
  Assist = 'assist',
  // 传阅
  Circulate = 'circulate',
  // 是否显示内部流转
  ShowInterTransfer = 'showInterTransfer',
  // 内部流转
  InterTransfer = 'interTransfer',
  // 是否显示驳回操作
  ShowReject = 'showReject',
  // 驳回到开始
  RejectToStart = 'rejectToStart',
  // 驳回到上一步
  Reject = 'reject',
  // 驳回到指定活动
  RejectToFixded = 'rejectToFixded',
}

/** 流程操作文本 */
export const flowActionText = 'FLOW_ACTION_TEXT';

/** 操作选择框code映射接口键 */
export const action2InterfaceKey = {
  [FlowActions.Urge]: 'cloudpivot.flow.action.urge',
};
