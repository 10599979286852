import Vue from 'vue';
import Vuex from 'vuex';
import workPlatform from './modules/work-platform';
import Global from './modules/global';
import { WorkPlatformService } from '../api/work-platform';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {
    async setLogoFile () {
      const iconLink = document.getElementsByTagName('link')[0];
      const logoEl = document.getElementById('logo');
      const openLogoConfig = localStorage.getItem('openLogoConfig');
      if (!openLogoConfig) {
        iconLink.href = 'favicon.ico';
        return;
      }

      try {
        const logoData = await WorkPlatformService.getLogoConfig();
        if (logoData.success && logoData.data?.length) {
          /**
           * logoFileId: 平台logo
           * faviconId: 页签logo
           */
          let logoId = '';
          let faviconId = '';
          for (const item of logoData.data) {
            if (item.propertyCode === 'logoFileId') {
              logoId = item.propertyValue;
            } else if (item.propertyCode === 'faviconFileId') {
              faviconId = item.propertyValue;
            }
          }
          if (faviconId === '') {
            iconLink.href = 'favicon.ico';
          }
          if (logoId && logoEl) {
            (logoEl as HTMLImageElement).src = `/api/webapi/file/common/download?refId=${logoId}` +
            `&accessToken=${localStorage.getItem('token')}`;
          }
          if (faviconId && iconLink) {
            iconLink.href = `/api/webapi/file/common/download?refId=${faviconId}` +
            `&accessToken=${localStorage.getItem('token')}`;
          }
        }
        if (logoData.success && logoData.data?.length === 0) {
          iconLink.href = 'favicon.ico';
        }
      } catch (err) {}
    },
  },
  modules: {
    workPlatform,
    Global,
  },
});
